import { widgetWrapper } from './wrapper/WidgetWrapper';
import { translateService } from './service/TranslateService';
import { installService } from './service/InstallService';

window.addEventListener('DOMContentLoaded', async () => {
	const container = document.querySelector('#google-translate');
	const metaparams = container.querySelector('[data-google-translate-params]');
	const params = JSON.parse(metaparams.dataset.googleTranslateParams);

	installService.setParams(params);
	translateService.setParams(params);
	widgetWrapper
		.setContainer(container)
		.setParams(params)
		.render();

	let activelang = document.cookie.match('(^|;) ?googtrans=([^;]*)(;|$)');
	activelang = activelang ? activelang[2].split('/')[2] : null;
	if (activelang) translateService.autoTranslate(500, activelang);
});
