/* eslint-disable */

import { translateService } from '../service/TranslateService'

class WidgetTemplate {

	getHtml (languages) {
		return /* html */ `
			<div id="original-google-translate" class="d-none"></div>
			<ul class="navbar-nav google-translate-wrapper">
				${languages.map(lang => lang.template.getHtml(lang.name, this.getCurrentLang())).join('')}
			</ul> 
		`
	}

	getCurrentLang  () {
		let activelang = document.cookie.match('(^|;) ?googtrans=([^;]*)(;|$)')
		activelang = activelang ? activelang[2].split('/')[2] : null
		
		if (!activelang)
			activelang = translateService.params.siteLanguage
		return activelang
	}

}

const widgetTemplate = new WidgetTemplate()
export {
	WidgetTemplate,
	widgetTemplate
}
