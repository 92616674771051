/* eslint-disable */
import { installService } from '../service/InstallService'
import { widgetWrapper } from '../wrapper/WidgetWrapper'
class TranslateService {
	inprogress = false
	current = false
	setParams (params) {
		this.params = params
		this.current = this.params.siteLanguage
	}

	setGTWidget (GTwidget) {
		this.gtWidget = GTwidget;
		const options = GTwidget.querySelectorAll('option')
		let buttonlist = {};
		[...options].forEach(option => {
			buttonlist[option.value] = option
		})
		this.buttonlist = buttonlist
	}

	autoTranslate(time, lang){
		this.current = lang
		let cb = () => {widgetWrapper.render()}
		
		installService.setCB(cb)
		installService.setAutoInstall(time)
	}

	translate (event, element, lang) {
		if(!lang) return
		if(installService.inprogress) return
		if(this.inprogress) return

		if(!installService.instaled){
			let cb = () => {this.translate(event, element, lang)}
			installService.setCB(cb)
			installService.install()
			return
		}
		
		if(lang === this.currentLang()) return;

		if(lang === this.params.siteLanguage) {
			document.querySelector('.skiptranslate').querySelector('iframe').contentDocument.querySelectorAll('button')[3].click()
			widgetWrapper.render()
		}
		else {
			this.gtWidget.value = lang
			const event = new Event('change')
			this.gtWidget.dispatchEvent(event)
			this.inprogress = true
			setTimeout(() => {
				this.inprogress=false
				widgetWrapper.render()
			},1000)
		}
		
		// document.getElementById('modal-google-translate').classList.remove('d-none')

	}

	currentLang(){
		let activelang = document.cookie.match('(^|;) ?googtrans=([^;]*)(;|$)')
		activelang = activelang ? activelang[2].split('/')[2] : null
		return activelang
	}
	
}

const translateService = new TranslateService()
export {
	TranslateService,
	translateService
}
