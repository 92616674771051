/* eslint-disable */
import { on, hash } from "../utils"
import { translateService } from '../service/TranslateService'
class LangTemplate {
	name
	template
	getHtml (name, active) {
		return /* html */ `
		<a  ${on('click', translateService, 'translate', name)}
			class="${active == name ? 'active ' : ' '}nav-link flag nturl notranslate google-translate__lang" 
			href='' 
		>${name}
		</a>
		`
	}
}

const langTemplate = new LangTemplate()
export {
	LangTemplate,
	langTemplate
}
