/* eslint-disable */

import { widgetTemplate } from '../template/WidgetTemplate'
import { langTemplate } from '../template/LangTemplate'
import { Lang } from '../entities/Lang'

class WidgetWrapper {
	container
	params
	languages

	setContainer (container) {
		this.container = container
		this.template = widgetTemplate
		return this
	}

	setParams (params) {
		this.params = params
		this.languages = params.language.map(this._createLang.bind(this))
		return this
	}

	render () {
		this.container.innerHTML = this.template.getHtml(this.languages)
	}
	
	_createLang (element) {
		const languages = new Lang()
		languages.name = element
		languages.template = langTemplate
		return languages
	}

}

const widgetWrapper = new WidgetWrapper()
export {
	WidgetWrapper,
	widgetWrapper
}
