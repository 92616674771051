/* globals google */
/* eslint-disable */
import { hash } from "../utils"
import { translateService } from '../service/TranslateService'
class InstallService {
	inprogress = false
	instaled = false
	params

	setParams (params) {
		this.params = params
	}

	setAutoInstall(time){
		setTimeout(this.install.bind(this), time) //self install
	}

	setCB (cb) {
		this.cb = cb
	}

	install () {
		if (this.instaled === true) return
		if (this.inprogress === true) return
		this.inprogress = true
		let scriptTag = document.createElement('script')
		scriptTag.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=' + this.registerCB())
		document.body.appendChild(scriptTag)
	}

	registerCB()
	{ 
		let name
		do {
			name = `GoogleTranslate_${hash(10)}`
		} while(window[name] !== undefined)

		window[name] = this.afterInstall()
		return name
	}

	afterInstall () {
		return () => {
			this.inprogress = false
			this.instaled = true
			new google.translate.TranslateElement({pageLanguage: this.params.siteLanguage, includedLanguages: this.params.language.join(','), autoDisplay: false}, 'original-google-translate')

			Array.apply(null, document.querySelectorAll('link[href="https://translate.googleapis.com/translate_static/css/translateelement.css"]')).forEach(function (node) {
				node.parentNode.removeChild(node)
			})

			document.getElementsByTagName('body')[0].classList.add('gt')
			this.observe() 
		}
	}

	observe() {
		this.observer = new MutationObserver(this.check.bind(this))
		this.observer.observe(document.querySelector('.goog-te-combo'), {attributes: true, childList: true, subtree: true })
	}
	
	check() {
		if(document.querySelector('.goog-te-combo').querySelectorAll('option')) {
			this.observer.disconnect()
			translateService.setGTWidget(document.querySelector('.goog-te-combo'));
			if(this.cb) {
				this.cb()
				this.cb = false
			}
		}
	}
}

const installService = new InstallService()
export {
	InstallService,
	installService
}
